import {
  CardHeader,
  Chip as MuiChip, CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { Card, Paper } from '../../components/StyledComponents';
import {CustomTooltip} from "../../styles/components/CustomTooltip";
import {HelpCircle} from "react-feather";

const Chip = styled(MuiChip)<{ color: string }>`
  height: 22px;
  box-sizing: border-box;
  padding: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  border-radius: 16px;
  text-transform: capitalize;
  span {
    height: 20px;
  }
  &.success{
    background-color: #D1FAE5;
    color: #065F46;
  }
  &.error{
    background-color: #FEE2E2;
    color: #991B1B;
  }
`;


const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
  border: 1px solid #E5E7EB;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  font-size: 14px;
  line-height: 20px;
  max-height: 388px;
  .MuiTableCell-root{
    font-size: 14px;
    line-height: 20px;
  }
  thead th {
    background: #F9FAFB;
  }
  tbody th {
    max-width: 250px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

type TableProps = {
  data?: PropsActivity[];
  loading: boolean;
};

type PropsActivity = {
  id: number | string,
  name: string,
  action: string,
  date: number,
  status: string,
  platform: string,
};

const DashboardTable: React.FC<TableProps> = ({data, loading}) => {

  return (
    <Card>
      {loading && (
        <div className="spinner-wrapper">
          <CircularProgress/>
        </div>
      )}
      {!data?.length && !loading &&
        <CardHeader
          title={'Your account has been inactive. Please start a subscription to resume.'}
        />
      }
      {!!data?.length && !loading &&(
      <Paper>
        <TableWrapper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>App</TableCell>
                <TableCell>Item</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
              <TableBody>
                {!!data?.length && data.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell className='dash-app-act-title'>{row.platform}</TableCell>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell>
                      {new Date(row.date).toLocaleString()}
                    </TableCell>
                    <TableCell sx={{textTransform: 'capitalize'}}>{row.action}</TableCell>
                    <TableCell>
                      <Chip
                        className={row.status === 'success' ? 'success' : 'error'}
                        label={row.status}
                      />
                      {row.action === 'restore' && row.status !== 'success' && (
                        <CustomTooltip title={`Unable to restore. Check your ${row.platform} account to investigate any limitations.`}>
                          <HelpCircle className="info-icon" />
                        </CustomTooltip>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
          </Table>
        </TableWrapper>
      </Paper>
      )}
    </Card>
  )
};

export default DashboardTable;
