import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IconButton, Menu, MenuItem } from '@mui/material';
import React, { FunctionComponent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { avatar } from '../../../assets/icons/icons';
import { IUser } from '../../../models/models';
import { logout } from '../../../store/actions';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getUserSelector } from '../../../store/selectors';
import './style.scss';
import {Divider} from "../../StyledComponents";
import {iniFrame} from "../../../serviceWorker";

const AuthorizedUserItem: FunctionComponent = () => {
  const [anchorMenu, setAnchorMenu] = React.useState<HTMLElement | null>(null);
  const { t: translate } = useTranslation();
  const user: IUser | null = useAppSelector(getUserSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const toggleMenu = (event: React.SyntheticEvent) => {
    setAnchorMenu(event.currentTarget as HTMLElement);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleLogout = useCallback(async () => {
    await dispatch(logout(false));
    navigate('/auth/login');
  }, []);

  const navigateToProfile = useCallback(() => {
    setAnchorMenu(null);
    navigate('/profile')
  }, []);

  const navigateToContact = useCallback(() => {
    setAnchorMenu(null);
    navigate('/contact')
  }, []);

  const navigateToSupport = useCallback(() => {
    setAnchorMenu(null);
    window.open('https://support.backuplabs.io/hc/en-gb', '_blank');
  }, []);

  return (
    <div
      id="auth-user-item"
      aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
      aria-haspopup="true"
    >
      <img
        loading="lazy"
        onClick={navigateToProfile}
        src={user?.picture || avatar}
        className={`avatar ${!user?.picture ? 'default' : ''}`}
        onMouseOver={toggleMenu}
      />
      {/*<div onClick={navigateToProfile} className="user-name">{user?.name} {user?.family_name}</div>*/}
      <IconButton
        aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
        aria-haspopup="true"
        color="inherit"
        size="large"
        onMouseOver={toggleMenu}
      >
        {anchorMenu ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
      </IconButton>
      <Menu
        id="menu-appbar"
        autoFocus={false}
        anchorEl={anchorMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        MenuListProps={{onMouseLeave: closeMenu,}}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        <MenuItem onClick={navigateToProfile}>{translate('common.default_pages.profile')}</MenuItem>
        <MenuItem onClick={navigateToContact}>{translate('common.default_pages.contact_us')}</MenuItem>
        {!iniFrame() && (
          <MenuItem onClick={navigateToSupport}>{translate('common.default_pages.support')}</MenuItem>
        )}
        <Divider/>
        <MenuItem onClick={handleLogout}>{translate('common.default_pages.sign_out')}</MenuItem>
      </Menu>
    </div>
  );
};

export default AuthorizedUserItem;
