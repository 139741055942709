import { createAsyncThunk } from '@reduxjs/toolkit';
import { EApiStatuses } from '../../../constants';
import {
  CloudStoragePlatform,
  IBackupsVerPayload, IBillingAddressPayload,
  IBillingAddressSubscribePayload,
  IDailyStatusPayload,
  IError, IExtStorePayload, IPagePayload, IPromotionCode, IStoreLocation, ITaxUser
} from '../../../models/inner-models';
import { EPlatform, EPlatformNames } from '../../constants';
import { addPlatformIdentifier } from '../../functions';
import instance from '../../middlewares/api';
import {setUserPro} from "../user";

const getAllSubscriptions = createAsyncThunk(
  addPlatformIdentifier(EPlatform.getAllSubscriptions, EPlatformNames.all),
  async (_, thunkAPI) => {
    try {
      const resp = await instance.get('payments/get-platforms-trial-statuses');
      if (resp?.data?.result && resp.data.status === EApiStatuses.SUCCESS) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const isPro = !!Object.values(resp.data.result).filter(i => i.subscription_type === 'pro_plan' || i.trialStatus === 'active')?.length
        await thunkAPI.dispatch(setUserPro(isPro))
      }
      return (!resp?.data || resp.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ error: resp?.data?.message || 'Unauthorized' }) :
        resp.data.result;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const getInvoiceInfo = createAsyncThunk(
  addPlatformIdentifier(EPlatform.getInvoiceInfo, EPlatformNames.all),
  async (platform: string, thunkAPI) => {
    try {
      const resp = await instance.get(`/payments/stripe-subscription-latest-invoice/${platform}`);
      return (!resp?.data || resp.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ error: resp.data.message || 'Unauthorized'  }) :
        resp.data;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const getTaxInfo = createAsyncThunk(
  addPlatformIdentifier(EPlatform.getTaxInfo, EPlatformNames.all),
  async (payload: ITaxUser, thunkAPI) => {
    try {
      const resp = await instance.post(`/payments/stripe-get-tax-rate`, payload);
      return (!resp?.data || resp.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ error: resp.data.message || 'Unauthorized'  }) :
        resp.data.result;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const getStripePrices = createAsyncThunk(
  addPlatformIdentifier(EPlatform.getPrice, EPlatformNames.all),
  async (_, thunkAPI) => {
    try {
      const resp = await instance.get(`/payments/stripe-get-prices/`);
      return (!resp?.data || resp.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ error: resp.data.message }) :
        resp.data;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const getCoupon = createAsyncThunk(
  addPlatformIdentifier(EPlatform.getCoupon, EPlatformNames.all),
  async (payload: IPromotionCode, thunkAPI) => {
    try {
      const resp = await instance.get(`/payments/stripe-get-promotion-code?promotion_code=${payload.promotion_code}`);
      return resp?.data;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const setStatusDailyBackup = createAsyncThunk(
  addPlatformIdentifier(EPlatform.dailyStatus, EPlatformNames.all),
  async (payload: IDailyStatusPayload, thunkAPI) => {
    try {
      const resp = await instance.put(`/${payload.platform}/update-daily-backup-items-statuses`, payload.payload);
      return (!resp?.data || resp.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ error: resp.data.message }) :
        resp.data;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const setBillingAddressAndSubscribe = createAsyncThunk(
  addPlatformIdentifier(EPlatform.billingAddressAndItems, EPlatformNames.all),
  async (payload: IBillingAddressSubscribePayload, thunkAPI) => {
    try {
      const resp = await instance.post(`/payments/stripe-create-subscriptions`, payload);
      return (!resp?.data || resp.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ error: resp.data.message }) :
        resp.data.result;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const updBillingAddress = createAsyncThunk(
  addPlatformIdentifier(EPlatform.billingAddress, EPlatformNames.all),
  async (payload: IBillingAddressPayload, thunkAPI) => {
    try {
      const resp = await instance.put(`/payments/stripe-update-company`, payload);
      return (!resp?.data || resp.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ error: resp.data.message }) :
        resp.data.result;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const updStorageLocation = createAsyncThunk(
  addPlatformIdentifier(EPlatform.storeLoc, EPlatformNames.all),
  async (payload: IStoreLocation, thunkAPI) => {
    try {
      const resp = await instance.patch(`/user/change-user-platform-s3-region`, payload);
      return (!resp?.data || resp.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ error: resp.data.message }) :
        resp.data.result;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const backupAllByPlatform = createAsyncThunk(
  addPlatformIdentifier(EPlatform.backupAll, EPlatformNames.all),
  async (platform : string, thunkAPI) => {
    try {
      const resp = await instance.get(`${platform}/backup-all-items-now`, {});

      return (!resp?.data || resp.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ status: resp.data.status, error: resp.data.message, code: resp.data.code }) : resp.data;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const getBackupByItemsId = createAsyncThunk(
  addPlatformIdentifier(EPlatform.getItemsBackups, EPlatformNames.all),
  async (payload: IBackupsVerPayload, thunkAPI) => {
    try {
      const resp = await instance.post('user/get-items-backups', payload);
      return (!resp?.data || resp.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ error: resp.data.message }) :
        resp.data;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const getStatisticsData = createAsyncThunk(
  addPlatformIdentifier(EPlatform.getStatisticsData, EPlatformNames.all),
  async (_, thunkAPI) => {
    try {
      const resp = await instance.get('user/dashboard');
      return (!resp?.data || resp.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ error: resp?.data?.message || 'Unauthorized'  }) :
        resp.data.result;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const getDashboardActivity = createAsyncThunk(
  addPlatformIdentifier(EPlatform.getDashData, EPlatformNames.all),
  async (_, thunkAPI) => {
    try {
      const resp = await instance.get('user/dashboard/recent-activity');
      return (!resp?.data || resp.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ error: resp?.data?.message || 'Unauthorized'  }) :
        resp.data.result;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const getUserActions = createAsyncThunk(
  addPlatformIdentifier(EPlatform.getUserActions, EPlatformNames.all),
  async (payload: IPagePayload, thunkAPI) => {
    try {
      const resp = await instance.get(`user/actions-history?page=${payload.page}&perPage=${payload.perPage}`);
      return (!resp.data || resp.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ error: resp.data.message }) :
        resp.data.result;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const getUserItemsAmount = createAsyncThunk(
  addPlatformIdentifier(EPlatform.getUserAmount, EPlatformNames.all),
  async (_, thunkAPI) => {
    try {
      const resp = await instance.get('user/get-platforms-items-amount');
      // return (!resp.data || resp.data.status !== EApiStatuses.SUCCESS) ?
      //   thunkAPI.rejectWithValue({ error: resp.data.message }) :
      //   resp.data.result || {};
      return resp?.data?.result || {}
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const getCloudStorageUrl = createAsyncThunk(
  addPlatformIdentifier(EPlatform.cloudUrl, EPlatformNames.all),
  async (payload: IExtStorePayload, thunkAPI) => {
    try {
      const resp = await instance.get(`/user/get-cloud-storage-auth-url?cloudStorageName=${payload.storage}${!!payload.platform.length ? ('&platform=' + payload.platform) : ''}`, {});
      return (!resp?.data || resp.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ status: resp.data.status, error: resp.data.message, code: resp.data.code }) : resp.data?.result;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const removeCloudStorage = createAsyncThunk(
  addPlatformIdentifier(EPlatform.cloudUrlRemove, EPlatformNames.all),
  async (storage: string, thunkAPI) => {
    try {
      const resp = await instance.put(`/user/remove-user-cloud-storage`, {
        cloudStorageName: storage
      });
      return (!resp?.data || resp.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ status: resp.data.status, error: resp.data.message, code: resp.data.code }) : resp.data?.result;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const setCloudStorage = createAsyncThunk(
  addPlatformIdentifier(EPlatform.cloudData, EPlatformNames.all),
  async (data: CloudStoragePlatform, thunkAPI) => {
    try {
      const resp = await instance.post(`/user/add-platforms-backup-cloud-storages`, {
        platformsWithCloudStorages: data
      });
      return (!resp?.data || resp.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ status: resp.data.status, error: resp.data.message, code: resp.data.code }) : resp.data?.result;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export {
  getAllSubscriptions,
  getInvoiceInfo,
  getStatisticsData,
  getDashboardActivity,
  getStripePrices,
  backupAllByPlatform,
  getUserActions,
  setStatusDailyBackup,
  updBillingAddress,
  getBackupByItemsId,
  getUserItemsAmount,
  setBillingAddressAndSubscribe,
  getTaxInfo,
  getCoupon,
  getCloudStorageUrl,
  removeCloudStorage,
  setCloudStorage,
  updStorageLocation
}
